<template>
  <v-card class="accounts-aging">
    <v-card-title>Aging</v-card-title>
    <graph
      width="500px"
      height="200px"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar'
        },
        dataLabels: {
          enabled: true,
          formatter: v => this.formatCurrency(v, 'EUR'),
          offsetY: -20
        },
        labels: [
          'Current',
          '0 - 30',
          '30 - 60',
          '60 - 90',
          '90 +'
        ],
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        xaxis: {
          type: 'string',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      }
    },
    series () {
      return [{
        name: 'Balance',
        data: [
          3000,
          3000,
          50220,
          2001,
          300010
        ]
      }]
    }
  }
}
</script>
